import * as Dialog from "@radix-ui/react-dialog";
import closeDialog from "assets/icons/disalog-close.svg";

const AppDialog = ({ children, onClose = null }) => {
  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Overlay
          onClick={onClose}
          className="bg-dark bg-opacity-60 data-[state=open]:animate-overlayShow fixed inset-0"
        />
        <Dialog.Content>
          <div className="fixed w-screen h-screen flex flex-col justify-center items-center desktop:top-0 left-0 sm:bottom-0 sm:justify-end">
            {!!onClose && (
              <div
                className="flex justify-center w-full mb-6 cursor-pointer"
                onClick={() => onClose()}
              >
                <img src={closeDialog} alt="Close Dialog" loading="lazy" width="41px" />
              </div>
            )}
            <div className="flex flex-col bg-white rounded-lg w-auto desktop:overflow-hidden sm:overflow-auto max-w-[1040px] mx-auto sm:max-w-[99vw] sm:rounded-t-xl sm:rounded-b-none">
              {children}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AppDialog;
