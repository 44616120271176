import WarningIcon from "assets/icons/warning.svg";
import CloseIcon from "assets/icons/x-primary.svg";
import { Dialog } from "components/index";
import { Spinner } from "components";
import { useTranslation } from "react-i18next";

const Confirm = ({
  handleOnCancel,
  handleOnConfirm,
  title = "",
  isWarning = false,
  isLoading = false,
  confirmText = "",
  confirmBg = "bg-green",
}) => {
  const { t } = useTranslation();

  return (
    <Dialog>
      <div className="w-[500px] max-w-[95%] m-auto flex flex-col p-6 bg-white">
        <div className="flex justify-start cursor-pointer">
          <img src={CloseIcon} alt="Close Icon" loading="lazy" onClick={handleOnCancel} />
        </div>

        <div className="flex flex-col w-full justify-center">
          <div className="flex justify-center">
            <div className="flex rounded-full bg-[#F2F2F2] w-[82px] h-[82px] justify-center items-center">
              {isWarning && <img src={WarningIcon} alt="Warning Icon" loading="lazy" />}
            </div>
          </div>

          <div className="flex text-lg text-primary mt-4 justify-center item-center font-bold text-center">
            {title}
          </div>

          <div className="flex justify-between items-center mt-6 mb-2 sm:flex-col sm:w-full">
            {!!handleOnConfirm && (
              <button
                disabled={isLoading}
                className={`w-2/4 rounded-md flex justify-center items-center py-2 disabled:opacity-[0.5] sm:w-full sm:mb-2 ${confirmBg}`}
                onClick={handleOnConfirm}
              >
                {!isLoading && (
                  <span className="text-sm font-bold text-white mx-2">{confirmText}</span>
                )}
                {isLoading && <Spinner classes={"w-[20px]"} />}
              </button>
            )}

            {!!handleOnCancel && (
              <button
                disabled={isLoading}
                className="w-2/4 rounded-md bg-gray88 flex justify-center items-center py-2 ms-2 disabled:opacity-[0.5] sm:w-full sm:mx-auto"
                onClick={handleOnCancel}
              >
                <span className="text-sm font-bold text-primary">{t("Cancel")}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Confirm;
