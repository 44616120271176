import { useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const [year] = useState(new Date().getFullYear());
  return (
    <div className="w-100 bg-primary min-h-[26.06vh] sm:min-h-[10px] sm:h-[263px] flex-grow">
      <div className="flex flex-col justify-evenly items-center h-full pt-[60px] sm:pt-[33px]">
        <div className="flex flex-col justify-start">
          <div className="text-center font-bold text-2xl text-light mb-4">
            {t("Contact us easily via:")}
          </div>
          <div className="flex justify-center sm:flex-col items-center">
            <div className="flex text-xl font-bold text-secondary pe-4 ">
              <span className="pe-1">{t("E-mail")}:</span>
              <a href="mailto:hello@mazaj.app">hello@mazaj.app</a>
            </div>
            <div className="flex text-xl font-bold text-secondary ">
              <span className="pe-1">{t("WhatsApp")}:</span>
              <a href="https://wa.me/+9665656456373" dir="ltr" target="_blank" rel="noreferrer">
                +9665656456373
              </a>
            </div>
          </div>
        </div>

        <div className="text-xs font-normal text-light mt-16">
          All rights reserved Mazaj,Inc © {year}
        </div>
      </div>
    </div>
  );
};

export default Footer;
