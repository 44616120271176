import { APP_CONSTRAINTS } from "types/app";

// TYPES
export const setAppDimensions = (dimensions) => {
  const isLandscape = dimensions?.width > dimensions?.height && dimensions?.height <= 500;
  const isMobile = isLandscape ? dimensions?.height <= 769 : dimensions?.width <= 769;
  return {
    type: APP_CONSTRAINTS.SET_APP_DIMENSIONS,
    dimensions,
    isMobile,
    isLandscape,
  };
};

//\ End Types

// Actions

//\ End Actions
