import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

const PaginatedItems = ({ ...reset }) => {
  const { t } = useTranslation();

  const isMobile = window.innerWidth <= 769;

  return (
    <div className={"flex w-full justify-center items-center my-8"}>
      <ReactPaginate
        pageRangeDisplayed={1}
        marginPagesDisplayed={isMobile ? 1 : 3}
        breakLabel="..."
        nextLabel={t("Next >")}
        previousLabel={t("< Previous")}
        renderOnZeroPageCount={null}
        className={"flex justify-center items-center"}
        disabledClassName={"opacity-[0.7] cursor-not-allowed"}
        disabledLinkClassName={"opacity-[0.7] cursor-not-allowed"}
        pageLinkClassName={
          "inline-flex justify-center items-center text-xs font-bold rounded py-2 px-4 m-2 bg-gray-200 text-primary"
        }
        nextClassName={
          "inline-flex justify-center items-center text-xs font-bold rounded py-2 px-4 m-2 bg-gray-200 text-primary"
        }
        previousClassName={
          "inline-flex justify-center items-center text-xs font-bold rounded py-2 px-4 m-2 bg-gray-200 text-primary"
        }
        activeLinkClassName={"bg-[#5C4C3F] text-[#FFFFFF] active-page-class"}
        {...reset}
      />
    </div>
  );
};

export default PaginatedItems;

//https://www.npmjs.com/package/react-paginate
