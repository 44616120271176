import { SUPPLIERS_CONSTRAINTS } from "types/suppliers";

const initState = {
  list: [],
  meta: null,
  links: null,
  details: null,
  error: null,
  isLoading: false,
  items: [],
  itemsMeta: null,
  itemDetails: null,
  sortingList: [],
  filter: null,
  sectionsList: [],
};

const Suppliers = (state = initState, action) => {
  switch (action.type) {
    case SUPPLIERS_CONSTRAINTS.SET_SUPPLIERS_LIST:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_SUPPLIER_DETAILS:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_SUPPLIERS_ITEMS:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_SUPPLIERS_ITEM_DETAILS:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_SORTING_LIST:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_LIST_FILTER:
      return { ...state, ...action };

    case SUPPLIERS_CONSTRAINTS.SET_SECTIONS_LIST:
      return { ...state, ...action };

    default:
      return state;
  }
};

export default Suppliers;
