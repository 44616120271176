import { setAppDimensions } from "actions/app.action";
import AppWrapper from "components/Wrapper";
import Storage from "libraries/storage";
import { changeAppLanguage } from "helper/utitlity";
import { useEffect } from "react";
import { Provider } from "react-redux";
import Reducer from "reducers/index";
import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import { useTranslation } from "react-i18next";
import AppRoute from "routes/index";
import { AuthWrapper } from "./components";
import "react-toastify/dist/ReactToastify.css";
let composeEnhancers = compose;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
export const AppStore = createStore(Reducer, composeEnhancers(applyMiddleware(thunk)));

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // setup the language
    changeAppLanguage(i18n, Storage.get("lang"), false);
  }, [i18n]);

  return (
    <Provider store={AppStore}>
      <AppWrapper>
        <AuthWrapper>
          <AppRoute />
        </AuthWrapper>
      </AppWrapper>
    </Provider>
  );
};

export default App;

//Toast Docx => https://fkhadra.github.io/react-toastify/introduction/
