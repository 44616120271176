import React from "react";
import Select from "react-dropdown";
import "react-dropdown/style.css";

const AppSelect = ({
  placeholder,
  onChange = "",
  items,
  isMulti = false,
  value,
  name = "",
  ...reset
}) => {
  return (
    <Select
      value={value}
      isMulti={isMulti}
      name={name}
      placeholder={placeholder}
      className="basic-multi-select w-full rounded-md text-sm text-start"
      classNamePrefix="select"
      placeholderClassName="text-sm text-primary text-start"
      menuClassName={"p-2"}
      onChange={onChange}
      options={items}
      {...reset}
    />
  );
};

export default AppSelect;
//https://react-select.com/home
