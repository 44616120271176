import Logo from "assets/icons/logo.svg";
import Cart from "./Cart";
import Requests from "./Requests";
import UserDetails from "./UserDetails";
import MobileMenu from "./MobileMenu";

const Header = () => {
  return (
    <div className="w-100 bg-default h-[109px] py-[35px] px-1 shadow" dir="rtl">
      <div className="flex justify-between max-w-[1140px] mx-auto">
        <div className="flex items-center">
          <div className="desktop:hidden">
            <MobileMenu />
          </div>
          <Cart />
          <div className="sm:hidden flex items-center">
            <Requests />
            <UserDetails />
          </div>
        </div>
        <a href={"/"}>
          <img src={Logo} alt="Mazaj Logo" className="w-[171px] h-[45px]" loading="lazy" />
        </a>
      </div>
    </div>
  );
};

export default Header;
