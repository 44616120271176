import Storage from "libraries/storage";

export const changeAppLanguage = async (i18n, selectLang = null, isReload = true) => {
  const lang = selectLang ? selectLang : i18n.language === "ar" ? "en" : "ar";
  await i18n.changeLanguage(lang, (data) => {
    document.documentElement.lang = lang;
    document.documentElement.dir = lang !== "en" ? "rtl" : "ltr";
    Storage.set("lang", lang);
    Storage.set("i18nextLng", lang);
  });
  if (isReload) {
    window.location.reload();
  }
};
