import { toast } from "react-toastify";
import { USER_CONSTRAINTS } from "types/user";
import axios from "libraries/request";
import Storage from "../libraries/storage";
import { STORAGE_CONSTRAINT } from "types/storage";
import ENDPOINTS from "libraries/endpoints";

// TYPES
export const setUserLoggedIn = (session) => ({
  type: USER_CONSTRAINTS.SET_USER_SESSION,
  session,
});

export const getUserLoggedIn = () => ({
  type: USER_CONSTRAINTS.GET_USER_SESSION,
});

export const failUserLoggedIn = (error) => ({
  type: USER_CONSTRAINTS.FAIL_USER_SESSION,
  error,
});

export const setUserProfile = (profile) => ({
  type: USER_CONSTRAINTS.SET_USER_PROFILE,
  profile,
});

export const setLoader = (isLoading = false) => ({
  type: USER_CONSTRAINTS.SET_LOADING,
  isLoading,
});

export const setProfileNotifications = (notifications = false) => ({
  type: USER_CONSTRAINTS.SET_PROFILE_NOTIFICATIONS,
  notifications,
});

//\ End Types

// Actions
export const doLogIn = (data) => async (dispatch) => {
  return axios
    .post(ENDPOINTS.AUTH, data)
    .then(async (session) => {
      Storage.set(STORAGE_CONSTRAINT.USER_SESSION, session);
      await dispatch(setUserLoggedIn(session));
      await dispatch(getUserLoggedIn());
      await dispatch(getProfile());
    })
    .catch(async ({ message }) => {
      Storage.remove(STORAGE_CONSTRAINT.USER_SESSION);
      await dispatch(setUserLoggedIn(null));
      await dispatch(failUserLoggedIn(message));
    });
};

export const getProfile = () => async (dispatch) => {
  return axios
    .get(ENDPOINTS.PROFILE)
    .then(async (response) => {
      await dispatch(setUserProfile(response));
    })
    .catch(async ({ message }) => {
      await dispatch(setUserProfile(null));
      toast.error(message);
    });
};

export const getProfileNotifications = () => async (dispatch) => {
  return axios
    .get(`${ENDPOINTS.PROFILE}/info`)
    .then(async (response) => {
      await dispatch(setProfileNotifications(response.data));
    })
    .catch(async ({ message }) => {
      await dispatch(setProfileNotifications(null));
      toast.error(message);
    });
};

export const requestResetPassword = (data) => async () => {
  return axios.post(ENDPOINTS.RESET_PASSWORD, data);
};

export const requestProfileResetPassword = (data) => async () => {
  return axios.put(ENDPOINTS.PROFILE_RESET_PASSWORD, data);
};

export const doLogOut =
  (isLogoutRequested = true) =>
  async (dispatch) => {
    await dispatch(setLoader(true));
    if (isLogoutRequested) {
      await axios.post(ENDPOINTS.LOGOUT);
    }
    Storage.remove(STORAGE_CONSTRAINT.USER_SESSION);
    await dispatch(getUserSession());
    await dispatch(setLoader(false));
  };

export const getUserSession = () => async (dispatch) => {
  const session = Storage.get(STORAGE_CONSTRAINT.USER_SESSION);
  if (session) {
    return await dispatch(setUserLoggedIn(session));
  }

  await dispatch(setUserLoggedIn(null));
};

export const doForgetPassword = (data) => async (dispatch) => {
  return axios.post(ENDPOINTS.FORGET_PASSWORD, data);
};

export const myProfileForgetPassword = (data) => async (dispatch) => {
  return axios.post(`${ENDPOINTS.PROFILE}/forget-password`, data);
};
export const resetMyProfilePassword = (data) => async (dispatch) => {
  return axios.put(`${ENDPOINTS.PROFILE}/reset-password`, data);
};
export const validateMyProfileCode = (data) => async (dispatch) => {
  return axios.post(`${ENDPOINTS.PROFILE}/validate-reset-code`, data);
};
export const validateMyProfileEmail = (data) => async (dispatch) => {
  return axios.post(`${ENDPOINTS.PROFILE}/validate-email`, data);
};
//\ End Actions
