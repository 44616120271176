import i18n from "i18next";
import ar from "lang/ar.json";
import en from "lang/en.json";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    fallbackLng: "en",
    resources: {
      ar: {
        translation: { ...ar },
      },
      en: {
        translation: { ...en },
      },
    },
    lng: "en",
  });

export default i18n;
