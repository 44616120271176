const ENDPOINTS = {
  BASE_URL: process.env.REACT_APP_BACKEND_CORE_URL,
  AUTH: "/oauth/token",
  SUPPLIERS: "/suppliers",
  FORGET_PASSWORD: "/password/email",
  PROFILE: "/profile",
  RESET_PASSWORD: "/password/reset/email",
  PROFILE_RESET_PASSWORD: "/profile/reset-password",
  CART: "/carts",
  LOGOUT: "/logout",
  ORDERS: "/orders",
};

export default ENDPOINTS;
