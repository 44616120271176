import { ORDERS_CONSTRAINTS } from "types/orders";

const initState = {
  list: [],
  items: null,
  listMeta: null,
  itemsMeta: null,
  expandItemId: null,
};

const Cart = (state = initState, action) => {
  switch (action.type) {
    case ORDERS_CONSTRAINTS.SET_LIST:
    case ORDERS_CONSTRAINTS.SET_ITEMS_LIST:
    case ORDERS_CONSTRAINTS.SET_EXPAND_ITEM_ID:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default Cart;
