import TruckLoaderGIf from "assets/icons/truck-loader.gif";

const GifLoader = ({ classes = "w-24 h-24 my-12 mx-auto" }) => {
  return (
    <div className={`flex flex-col justify-center items-center ${classes}`}>
      <img src={TruckLoaderGIf} alt="Truck Loader GIf" />
    </div>
  );
};

export default GifLoader;
