import { USER_CONSTRAINTS } from "types/user";

const initState = {
  session: null,
  profile: null,
  error: null,
  isLoggedIn: false,
  isLoading: false,
  notifications: null,
};

const User = (state = initState, action) => {
  switch (action.type) {
    case USER_CONSTRAINTS.SET_USER_SESSION:
      return {
        ...state,
        session: { ...action.session },
        isLoggedIn: !!action?.session,
      };

    case USER_CONSTRAINTS.SET_USER_PROFILE:
      return {
        ...state,
        ...action,
      };
    case USER_CONSTRAINTS.FAIL_USER_SESSION:
      return {
        ...state,
        error: action.error,
        session: null,
        isLoggedIn: false,
      };

    case USER_CONSTRAINTS.SET_PROFILE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };

    case USER_CONSTRAINTS.SET_LOADING:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default User;
