import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/layouts/mobile.css";
import "./default-color.css";

const MultipleDaysCalendar = ({ innerRef, ...reset }) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      ref={innerRef}
      plugins={[<DatePanel />]}
      mobileLabels={{ OK: t("OK"), CANCEL: t("Cancel") }}
      {...reset}
    />
  );
};
export default MultipleDaysCalendar;
//https://shahabyazdi.github.io/react-multi-date-picker/
