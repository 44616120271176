import { doLogOut } from "actions/user.action";
import axios from "axios";
import { STORAGE_CONSTRAINT } from "types/storage";
import ENDPOINTS from "./endpoints";
import Storage from "./storage";
axios.defaults.baseURL = ENDPOINTS.BASE_URL;
import { AppStore } from "App";
import { toast } from "react-toastify";

axios.interceptors.request.use(
  async (config) => {
    let session = Storage.get(STORAGE_CONSTRAINT.USER_SESSION);
    config.headers["Accept"] = `application/json`;
    config.headers["Accept-Language"] = Storage.get(STORAGE_CONSTRAINT.LANG);

    if (session) {
      config.headers["Authorization"] = `${session?.token_type} ${session?.access_token}`;
    }

    return config;
  },

  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response?.data || response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      AppStore.dispatch(doLogOut(false));
      throw new axios.Cancel("Operation canceled by System");
      return;
    }

    if (error?.response?.status >= 400) {
      toast.error(error.response.data.message);
    }

    console.log("error", error.response);
    return Promise.reject(error?.response?.data || error?.response || error);
  }
);

export default axios;
