export const ORDERS_CONSTRAINTS = {
  SET_LIST: "SET_LIST",
  SET_ITEMS_LIST: "SET_ITEMS_LIST",
  SET_EXPAND_ITEM_ID: "SET_EXPAND_ITEM_ID",
};

export const ORDERS_STATUSES = {
  PENDING: 1,
  ACCEPTED: 2,
  PREPARING: 3,
  ON_THE_WAY: 4,
  DELIVERED: 5,
  REJECTED: 6,
  CANCELED: 7,
};
