const Storage = {
  set(key, value) {
    if (!key) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  },

  get(key) {
    let value = null;
    if (!!localStorage[key]) value = JSON.parse(localStorage[key]);
    return value;
  },

  check(key) {
    return !!localStorage[key] || false;
  },

  remove(key) {
    localStorage.removeItem(key);
  },

  removeAll() {
    const list = Object.keys(localStorage);
    list.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};

export default Storage;
