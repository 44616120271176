import React, { Suspense, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { Footer, Header, Loader } from "components";

import routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { getUserSession } from "../actions/user.action";
import { STORAGE_CONSTRAINT } from "../types/storage";
import Storage from "../libraries/storage";

const PrivateRoute = ({ Component, isLoggedIn, ...reset }) => {
  if (!!isLoggedIn) {
    return <Component {...reset} />;
  }

  return <Navigate to="/login" />;
};
const PublicRoute = ({ Component, isLoggedIn, ...reset }) => {
  if (!isLoggedIn) {
    return <Component {...reset} />;
  }

  return <Navigate to="/" />;
};
const MainRoute = ({ component: Component, isAuth, isLoggedIn, isShared, ...reset }) => {
  return (
    <>
      {isShared && <Component {...reset} />}
      {!isShared && (
        <>
          {isAuth && <PrivateRoute Component={Component} isLoggedIn={isLoggedIn} {...reset} />}
          {!isAuth && <PublicRoute Component={Component} isLoggedIn={isLoggedIn} {...reset} />}
        </>
      )}
    </>
  );
};

const AppRoute = () => {
  const userSession = useSelector((state) => state?.User);
  const [isLoggedIn, setIsLoggedIn] = useState(Storage.get(STORAGE_CONSTRAINT.USER_SESSION));

  useEffect(() => {
    setIsLoggedIn(userSession?.isLoggedIn);
  }, [userSession?.isLoggedIn]);

  useEffect(() => {
    setIsLoggedIn(Storage.get(STORAGE_CONSTRAINT.USER_SESSION));
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <main className="flex flex-col w-[100vw] min-h-[73.94vh] h-100 sm:w-full">
          {!!isLoggedIn && <Header />}
          <div
            className={`flex flex-col h-full w-full mx-auto ${isLoggedIn ? "max-w-[1140px]" : ""}`}
          >
            <Routes>
              {routes.map((props, index) => (
                <Route
                  {...props}
                  key={`page-route-${index}`}
                  element={<MainRoute isLoggedIn={isLoggedIn} {...props} />}
                />
              ))}
            </Routes>
          </div>
        </main>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoute;
