import { useTranslation } from "react-i18next";
import GifLoader from "./GifLoader";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <div className="fixed flex w-screen h-screen justify-center items-center flex-col bg-[#0B0B0BA1] top-0 left-0 z-[90000]">
      <div className=" flex flex-col rounded-xl overflow-hidden bg-[#F3F2F2] w-[295px] min-h-[262px]">
        <div className="relative h-[196px]">
          <GifLoader classes="w-full absolute -top-6" />
        </div>
        <div className="flex justify-center text-center items-center text-primary text-sm font-medium whitespace-pre-line">
          {t("Please wait we set things up for you, \nthis might take a few seconds.")}
        </div>
      </div>
    </div>
  );
};

export default Loader;
