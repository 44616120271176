import { lazy } from "react";
const Login = lazy(() => import("views/Auth/Login"));
const ForgotPassword = lazy(() => import("views/Auth/ForgotPassword"));
const Home = lazy(() => import("views/Home"));
const NotFound = lazy(() => import("views/404"));
const Suppliers = lazy(() => import("views/Suppliers"));
const Cart = lazy(() => import("views/Cart"));
const Requests = lazy(() => import("views/Requests"));
const Profile = lazy(() => import("views/Profile"));
const ResetPassword = lazy(() => import("views/Profile/ResetPassword"));
const AuthResetPassword = lazy(() => import("views/Auth/ResetPassword"));

const Routes = [
  {
    name: "login",
    path: "/login",
    exact: false,
    isAuth: false,
    title: "Login page",
    component: Login,
  },
  {
    name: "root",
    path: "/",
    exact: false,
    isAuth: true,
    title: "Home page",
    meta: {},
    component: Home,
  },
  {
    name: "suppliers",
    path: "/suppliers/:id",
    exact: false,
    isAuth: true,
    title: "Suppliers page",
    meta: {},
    component: Suppliers,
  },
  {
    name: "cart",
    path: "/cart",
    exact: false,
    isAuth: true,
    title: "Cart page",
    meta: {},
    component: Cart,
  },
  {
    name: "orders",
    path: "/requests",
    exact: false,
    isAuth: true,
    title: "Orders page",
    meta: {},
    component: Requests,
  },
  {
    name: "profile",
    path: "/profile",
    exact: true,
    isAuth: true,
    title: "Profile page",
    meta: {},
    component: Profile,
  },

  {
    name: "resetPassword",
    path: "/profile/reset-password",
    exact: false,
    isAuth: true,
    title: "Reset Password page",
    meta: {},
    component: ResetPassword,
  },

  {
    name: "MailResetPassword",
    path: "/password/reset",
    exact: false,
    isAuth: false,
    title: "Reset Password Email Redirect",
    meta: {},
    component: AuthResetPassword,
  },

  {
    name: "forgotPassword",
    path: "/forgot-password",
    exact: false,
    isAuth: false,
    title: "forgot password page",
    meta: {},
    component: ForgotPassword,
  },

  {
    name: "Not Found",
    path: "*",
    exact: false,
    isAuth: false,
    title: "Page Not Found",
    meta: {},
    isShared: true,
    component: NotFound,
  },
];

export default Routes;
