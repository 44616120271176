import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

const ValidatePassword = ({ password }) => {
  const [pValidate, setPValidate] = useState(null);
  const [isPassword, setIsPassword] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setPValidate({
      capital: /[A-Z]+/.test(password),
      lower: /[a-z]+/.test(password),
      special: /[@_\-$!%*#?&]+/.test(password),
      octaChar: /^(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(password),
      number: /(?=.*\d)+/.test(password),
    });

    setIsPassword(!!password);
  }, [password]);

  return (
    <div className="relative flex flex-col px-2 pt-2">
      <span
        className={`text-sm font-normal text-start ${
          isPassword && (pValidate?.lower ? "text-success" : "text-danger")
        }`}
      >
        {t("A lowercase")}
      </span>
      <span
        className={`text-sm font-normal text-start ${
          isPassword && (pValidate?.capital ? "text-success" : "text-danger")
        }`}
      >
        {t("A capital (uppercase) letter")}
      </span>
      <span
        className={`text-sm font-normal text-start ${
          isPassword && (pValidate?.number ? "text-success" : "text-danger")
        }`}
      >
        {t("A Number")}
      </span>
      <span
        className={`text-sm font-normal text-start ${
          isPassword && (pValidate?.octaChar ? "text-success" : "text-danger")
        }`}
      >
        {t("Minimum 8 characters")}
      </span>
    </div>
  );
};

export default ValidatePassword;
