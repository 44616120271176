import ArrowIcon from "assets/icons/header/arrow-up-down.svg";
import ProfileIcon from "assets/icons/header/profile.svg";
import { Menu } from "components/index";
import { changeAppLanguage } from "helper/utitlity";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { doLogOut, setLoader } from "actions/user.action";

const UserDetails = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const profile = useSelector((state) => state.User.profile);
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const handleOnLogout = async () => {
    await dispatch(doLogOut());
    setTimeout(() => {
      navigator("/login", { replace: true });
    }, 200);
  };

  useEffect(() => {
    if (!profile?.first_name) {
      setUserName(profile?.email);
    } else if (profile?.first_name) {
      setUserName(`${profile?.first_name || ""} ${profile?.last_name || ""}`);
    }
  }, [profile]);

  const changeLanguage = async () => {
    await dispatch(setLoader(true));
    await changeAppLanguage(i18n);
  };

  const MenuTile = () => {
    return (
      <div
        className="flex justify-center bg-white py-1.5 px-6 rounded-3xl items-center shadow cursor-pointer"
        aria-label="Customise options"
      >
        <div className="flex flex-col justify-center me-1">
          <img src={ProfileIcon} alt="user profile" loading="lazy" />
        </div>
        <div className="flex flex-col justify-between">
          <span className="flex text-xs font-bold text-primary px-1">{t("Welcome Your")}</span>
          <div
            className="text-xs font-normal px-1 text-primary truncate w-[100px] overflow-hidden"
            dir="ltr"
          >
            {userName}
          </div>
        </div>
        <div className="flex justify-center flex-col items-center ms-2">
          <img
            src={ArrowIcon}
            alt={"arrow up down"}
            className={`${isOpen ? "rotate-180" : ""}`}
            loading="lazy"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex mx-2 relative">
      <Menu
        Tile={MenuTile}
        List={[
          <div className="flex py-2 hover:bg-gray-100 px-1">
            <div
              onClick={changeLanguage}
              className="cursor-pointer w-full px-2 hover:underline text-start"
            >
              <span className="text-base font-bold text-primary">{t("عربي")}</span>
            </div>
          </div>,
          <div className="flex py-2 hover:bg-gray-100 px-1">
            <div
              className="cursor-pointer w-full px-2 hover:underline text-start"
              onClick={() => navigator("/profile")}
            >
              <span className="text-base font-bold text-primary">{t("Profile")}</span>
            </div>
          </div>,
          <div className="flex py-2 hover:bg-gray-100 px-1">
            <div
              className="cursor-pointer w-full px-2 hover:underline text-start text-danger"
              onClick={handleOnLogout}
            >
              <span className="text-base font-bold text-danger">{t("Sign out")}</span>
            </div>
          </div>,
        ]}
      />
    </div>
  );
};

export default UserDetails;
