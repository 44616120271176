import { CART_CONSTRAINTS } from "types/cart";

const initState = {
  list: [],
  details: null,
  items: null,
  repeatTypes: [],
  slots: [],
  firstAvailableDate: null,
  unAvailableDays: [],
};

const Cart = (state = initState, action) => {
  switch (action.type) {
    case CART_CONSTRAINTS.SET_CART_DETAILS:
    case CART_CONSTRAINTS.SET_REPEATER_REQUEST_TYPES:
    case CART_CONSTRAINTS.SET_AVAILABLE_SLOTS:
    case CART_CONSTRAINTS.SET_FIRST_AVAILABLE_DATE:
    case CART_CONSTRAINTS.SET_UNAVAILABLE_DAYS:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default Cart;
