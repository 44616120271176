import { APP_CONSTRAINTS } from "types/app";

const initState = {
  dimensions: null,
  isMobile: false,
  isLandscape: false,
};

const App = (state = initState, action) => {
  switch (action.type) {
    case APP_CONSTRAINTS.SET_APP_DIMENSIONS:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default App;
