import { Loader } from "components/index";
import Storage from "libraries/storage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getUserSession } from "actions/user.action";
import { STORAGE_CONSTRAINT } from "types/storage";

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.User.isLoading);
  const session = Storage.get(STORAGE_CONSTRAINT.USER_SESSION);

  const prepareUserData = async () => {
    await dispatch(getUserSession());
    if (session) {
      await dispatch(getProfile());
    }
  };

  useEffect(() => {
    prepareUserData();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {children}
    </>
  );
};

export default AuthWrapper;
