import burgerIcon from "assets/icons/header/burger.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBack from "assets/icons/arrow-back-primary.svg";
import AvatarIcon from "assets/icons/avatar-primary.svg";
import { doLogOut, setLoader } from "actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { changeAppLanguage } from "helper/utitlity";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const profile = useSelector((state) => state.User.profile);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [displayName, setDisplayName] = useState("");

  const handleOnLogout = async () => {
    await dispatch(doLogOut());
    navigator("/login", { replace: true });
  };

  const handleOnRedirect = async (page) => {
    await navigator(page);
    setIsOpen(false);
  };

  const changeLanguage = async () => {
    await dispatch(setLoader(true));
    await changeAppLanguage(i18n);
  };

  useEffect(() => {
    if (!!profile?.first_name || !!profile?.last_name) {
      setDisplayName(`${profile?.first_name} ${profile?.last_name}`);
    } else {
      setDisplayName(profile?.email);
    }
  }, [profile]);

  return (
    <div className="flex flex-col py-2 px-4">
      <img
        src={burgerIcon}
        alt="Burger menu"
        loading="lazy"
        width={"40px"}
        onClick={() => setIsOpen(true)}
      />

      {isOpen && (
        <div className="fixed w-screen h-screen top-0 left-0 bg-dark bg-opacity-60 z-40">
          <div className="flex flex-col w-[90%] max-w-[400px] min-w-[290px] h-screen bg-white px-4 py-8">
            <div className="flex m-2">
              <img
                src={ArrowBack}
                alt="Arrow Back"
                loading="lazy"
                width={"20px"}
                className={"rotate-180 rtl:rotate-0"}
                onClick={() => setIsOpen(false)}
              />
              <span className="text-2xl text-primary font-bold mx-4">{t("Menu")}</span>
            </div>
            <div className="flex m-2">
              <img src={AvatarIcon} alt="Avatar Icon" loading="lazy" width={"50px"} />
              <div className="flex flex-col mx-4">
                <div className="text-base text-primary font-bold">{t("Welcome Your")}</div>
                <div className="text-sm text-primary font-normal">{displayName}</div>
              </div>
            </div>
            <div className="flex mx-2">
              <div
                className="cursor-pointer w-full border-b-[1px] p-4 border-b-gray88"
                onClick={() => handleOnRedirect("/")}
              >
                <span className="text-base font-semibold text-primary">{t("Home Page")}</span>
              </div>
            </div>
            <div className="flex mx-2">
              <div
                className="cursor-pointer w-full px-4 border-b-[1px] py-4 border-b-gray88"
                onClick={() => handleOnRedirect("requests")}
              >
                <span className="text-base font-semibold text-primary">{t("Orders")}</span>
              </div>
            </div>
            <div className="flex mx-2">
              <div
                className="cursor-pointer w-full border-b-[1px] p-4 border-b-gray88"
                onClick={changeLanguage}
              >
                <span className="text-base font-semibold text-primary">{t("عربي")}</span>
              </div>
            </div>
            <div className="flex mx-2">
              <div className="cursor-pointer w-full p-4" onClick={handleOnLogout}>
                <span className="text-base font-semibold text-rose-500">{t("Sign out")}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
