import { setAppDimensions } from "actions/app.action";
import Storage from "libraries/storage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

const AppWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.App.isMobile);

  const handleResize = async () => {
    await dispatch(
      setAppDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    );
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {children}
      <ToastContainer
        position={isMobile ? "top-center" : "top-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={Storage.get("lang") === "ar"}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AppWrapper;
