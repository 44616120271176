import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/layouts/mobile.css";
import "./default-color.css";

const SingleDayCalendar = ({ innerRef, ...reset }) => {
  const { t } = useTranslation();
  return (
    <DatePicker
      ref={innerRef}
      className="default"
      mobileLabels={{ OK: t("OK"), CANCEL: t("Cancel") }}
      {...reset}
    />
  );
};
export default SingleDayCalendar;
//https://shahabyazdi.github.io/react-multi-date-picker/
