import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const Requests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnNavigate = () => {
    navigate("requests");
  };
  return (
    <div className="flex mx-2">
      <div
        onClick={handleOnNavigate}
        className="flex justify-center bg-white py-3 px-3 rounded-3xl items-center shadow cursor-pointer"
      >
        <span className="flex text-sm font-bold text-primary px-1">{t("Orders")}</span>
      </div>
    </div>
  );
};

export default Requests;
