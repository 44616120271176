import { combineReducers } from "redux";
import User from "reducers/user";
import Suppliers from "reducers/suppliers";
import Cart from "reducers/cart";
import Orders from "reducers/orders";
import App from "reducers/app";

export default combineReducers({
  User,
  Suppliers,
  Cart,
  Orders,
  App,
});
