import { getProfileNotifications } from "actions/user.action";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cartIcon from "assets/icons/header/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Cart = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const notifications = useSelector((state) => state.User.notifications);
  const [itemsLength, setItemsLength] = useState(0);
  const dispatch = useDispatch();

  const openCart = () => {
    navigator("cart");
  };

  useEffect(() => {
    let length = 0;
    if (!!parseInt(notifications?.cart_items_quantity)) {
      length =
        parseInt(notifications?.cart_items_quantity) <= 99
          ? notifications?.cart_items_quantity
          : "+99";
    }

    let title = "Mazaj B2B";
    if (length) {
      title = `(${length}) ${title}`;
    }

    setItemsLength(length);
    document.getElementsByTagName("title")[0].innerHTML = title;
  }, [notifications]);

  const setProfileNotifications = async () => {
    await dispatch(getProfileNotifications());
  };

  useEffect(() => {
    (async () => {
      await setProfileNotifications();
    })();
  }, []);

  return (
    <div className="flex mx-2">
      <div
        className="flex justify-center bg-primary py-3 px-3 rounded-3xl items-center shadow cursor-pointer relative"
        onClick={openCart}
      >
        {!!itemsLength && (
          <span className="flex text-sm font-bold text-white mx-2 desktop:hidden">
            {itemsLength}
          </span>
        )}

        <div className="flex flex-col justify-center px-1 min-h-[14px] min-w-[22px]">
          <img src={cartIcon} alt="Cart Icon" loading="lazy" width="12px" height="12px" />
        </div>
        <span className="flex text-sm font-bold text-white px-1 sm:hidden">{t("Cart")}</span>
        {!!itemsLength && (
          <div className="absolute top-[-10px] right-[-10px] bg-[#eb5757] rounded-full min-w-[25px] flex justify-center items-center border-2 border-default">
            <span className="flex text-sm font-bold text-white px-1 min-[769px]:hidden sm:-indent-[999px] overflow-hidden">
              {itemsLength}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
